import {
  Component, Vue,
} from 'vue-property-decorator';
import PageLayout from '@/layout/Page.vue';
import GridTemplate from '@/components/GridTemplate.vue';
import AddHostel from '@/components/AddHostel.vue';
import {
  BreadcrumbInterface,
} from '@/layout/types';

interface Command {
  image: string
  name: string
  description: string
  url?: string
}

@Component({
  components: {
    PageLayout,
    GridTemplate,
    AddHostel,
  },
  metaInfo() {
    return this.$route.meta?.metaInfo;
  },
})
export default class About extends Vue {
  public get breadcrumbs(): BreadcrumbInterface[] {
    return [
      {
        title: this.$route.meta?.breadCrumb,
        active: true,
      },
    ];
  }

  public get command(): Command[] {
    return [
      {
        image: '/img/command/yarkov.jpg',
        name: 'Ярков Алексей',
        description: 'Автор идеи и ведущий разработчик проекта',
        url: 'https://vk.com/id530927534',
      },
    ];
  }
}
